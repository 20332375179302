import React, {
  Fragment,
  useState,
  useEffect,
  useRef,
  useCallback,
} from "react"
import {
  LegacyCard,
  FormLayout,
  InlineError,
  TextField,
  LegacyStack,
  Button,
  ChoiceList,
  Thumbnail,
  Modal,
  Icon,
  Text,
} from "@shopify/polaris";
import { DeleteIcon, ChevronUpIcon } from "@shopify/polaris-icons";
import ProductPickerModal from "../../../../components/productPickerModal"
import selectPayloadToPPData from "../../../../helpers/selectPayloadToPPData"
import DiscountCart from "../common/discountCart/discountCart"
import update from "immutability-helper"
import styled from "styled-components"
const ProductPicker = styled.div`
  .mockInputContainer-error {
    box-shadow: 0px 0px 0px 2px #458fff;
  }
`
const DeleteWrapper = styled.div`
  cursor: pointer;
`
const FeaturedImageWrapper = styled.div`
  .featued-image-title {
    display: block;
    padding-bottom: 5px;
  }
  .Polaris-Stack__Item:last-of-type {
    display: flex;
    align-items: center;
  }
`
function ProductEdit(props) {
  const {
    shop,
    host,
    token,
    state,
    setState,
    productPickerShow,
    error,
    currency,
    currencySymbol = "$",
    index,
    variant,
    variants,
    product,
    productItem,
    title,
    description,
    featuredImage,
    setProductEdit,
  } = props
  const [productPickerVisible, setProductPickerVisible] = useState(false)
  const [inlineError, setInlineError] = useState(null)
  const [active, setActive] = useState(false)
  const handleModalChange = useCallback(() => setActive(!active), [active])
  const activator = <Button onClick={handleModalChange}>Change image</Button>
  const [selectedImage, setSelectedImage] = useState([])
  const handleImageChange = useCallback((value) => setSelectedImage(value), [])
  const updateFeaturedImage = () => {
    if (selectedImage.length > 0) {
      setState(update(state, { featuredImage: { $set: selectedImage[0] } }))
    }
    handleModalChange()
  }
  const productRef = useRef()
  useEffect(() => {
    const element = productRef.current
    const elementRect = element.getBoundingClientRect()
    const absoluteElementTop = elementRect.top + window.scrollY
    window.scrollTo({
      top: absoluteElementTop - 20,
      behavior: "smooth",
    })
  }, [])
  const deleteOneProduct = (index) => {
    setState(update(state, { product: { $splice: [[index, 1]] } }))
  }
  return (
    <div
      ref={productRef}
      style={{ marginTop: "1.6rem" }}
      className="product-edit"
    >
      <LegacyCard sectioned key={index ? index + 1 : 0}>
        <LegacyStack alignment="center">
          <LegacyStack.Item fill>
            <Text variant="headingSm" as="h3">product{index !== null && index + 1}</Text>
          </LegacyStack.Item>
          <LegacyStack.Item>
            <LegacyStack>
              <DeleteWrapper onClick={() => setProductEdit(-1)}>
                <Icon source={ChevronUpIcon}/>
              </DeleteWrapper>
              <DeleteWrapper onClick={() => deleteOneProduct(index)}>
                <Button icon={DeleteIcon} tone="critical"></Button>
              </DeleteWrapper>
            </LegacyStack>
          </LegacyStack.Item>
        </LegacyStack>
        <FormLayout>
          <Fragment>
            <ProductPicker>
              <div className="mockInputContainer">
                <p className="mockInputLabel" style={{ paddingTop: "10px" }}>
                  Offer product
                </p>
                <div
                  className={
                    (!variant && !variants) || !product
                      ? "mockInput mockInputContainer-error"
                      : "mockInput"
                  }
                  onClick={() => setProductPickerVisible(true)}
                >
                  {(!variant && !variants) || !product ? (
                    <span>Select a product</span>
                  ) : (
                    <div>
                      <img src={product.image} className="mockInputImage" />
                      {variants ? (
                        <span>
                          {product.title} |{" "}
                          {variants.map((variant) => variant.title).join("/")}
                        </span>
                      ) : (
                        <span>
                          {product.title} | {variant.title}
                        </span>
                      )}
                    </div>
                  )}
                </div>
                {error && error.product ? (
                  <InlineError
                    message={error.product}
                    fieldID="product-error"
                  />
                ) : (
                  false
                )}
                {
                  (product && typeof product === 'object' && Object.keys(product).length === 0) || (variants && Array.isArray(variants) && variants.length === 0) ? (
                    <InlineError
                    message="The selected upsell product or variant was deleted from your store. This product won’t show untill you select a new product."
                    fieldID="product-error"
                  />
                  ) : (
                    false
                  )
                }
              </div>
              {"product" === productPickerShow ? (
                <ProductPickerModal
                  host={host}
                  shop={shop}
                  token={token}
                  open={productPickerVisible}
                  onSelect={(selectPayload) => {
                    const data = selectPayloadToPPData(selectPayload)
                    if (!data) {
                      if (product) {
                        // Clear selection if one exists
                        index !== null
                          ? setState(
                              update(state, {
                                product: {
                                  [index]: {
                                    product: { $set: null },
                                    variants: { $set: null },
                                  },
                                },
                              })
                            )
                          : setState(
                              update(state, {
                                product: { $set: null },
                                variant: { $set: null },
                                variants: { $set: null },
                              })
                            )
                      }
                      return
                    }

                    !Array.isArray(state.product)
                      ? setState(
                          update(state, {
                            product: {
                              $set: [
                                {
                                  product: data.product,
                                  variants: data.variants,
                                  featuredImage:
                                    data.product?.images[0]?.originalSrc,
                                  compareAtPrice: {
                                    sym: currencySymbol,
                                    value: "",
                                  },
                                  description: "",
                                  title: "",
                                },
                              ],
                            },
                          })
                        )
                      : setState(
                          update(state, {
                            product: {
                              [index]: {
                                product: { $set: data.product },
                                variants: { $set: data.variants },
                                featuredImage: {
                                  $set:
                                    data.product?.images[0]?.originalSrc || null,
                                },
                                compareAtPrice: {
                                  $set: { sym: currencySymbol, value: "" },
                                },
                                description: { $set: "" },
                                title: { $set: "" },
                              },
                            },
                          })
                        )
                  }}
                  onClose={() => setProductPickerVisible(false)}
                  initialProduct={productItem}
                />
              ) : (
                false
              )}
            </ProductPicker>
          </Fragment>

          <TextField
            label="Offer product title"
            placeholder="Type the product title"
            onChange={(val) => {
              index !== null
                ? setState(
                    update(state, {
                      product: { [index]: { title: { $set: val } } },
                    })
                  )
                : setState(update(state, { title: { $set: val } }))
            }}
            value={title}
          />
          <TextField
            label="Description"
            placeholder="Type to product subtitle"
            onChange={(val) => {
              index !== null
                ? setState(
                    update(state, {
                      product: { [index]: { description: { $set: val } } },
                    })
                  )
                : setState(update(state, { description: { $set: val } }))
            }}
            value={description}
            autoComplete="off"
          />
          <LegacyCard sectioned>
            <DiscountCart
              state={state}
              setState={setState}
              currency={currency}
              currencySymbol={currencySymbol}
              isAi={false}
              index={index}
            />
          </LegacyCard>
          <FeaturedImageWrapper>
            <span className="featued-image-title">Featured image</span>
            <LegacyCard>
              <LegacyCard.Section>
                <LegacyStack>
                  <LegacyStack.Item fill>
                    <img
                      style={{
                        width: 60,
                        height: 60,
                        objectFit: "contain",
                        objectPosition: "center",
                        border: "1px solid #AEB4B9",
                        borderRadius: "5px",
                      }}
                      src={
                        featuredImage && product?.id
                          ? featuredImage
                          : "https://firebasestorage.googleapis.com/v0/b/upsell-shopify.appspot.com/o/200.png?alt=media&token=2cf839eb-05e5-49b1-a591-4fc02e78e94b"
                      }
                    />
                  </LegacyStack.Item>
                  <LegacyStack.Item style={{ display: "flex" }} className="test">
                    {product && (
                      <Modal
                        activator={activator}
                        open={active}
                        onClose={handleModalChange}
                        title="Images"
                        primaryAction={{
                          content: "Add image",
                          onAction: updateFeaturedImage,
                        }}
                        secondaryActions={[
                          {
                            content: "Cancel",
                            onAction: handleModalChange,
                          },
                        ]}
                      >
                        <Modal.Section>
                          <ChoiceList
                            choices={
                              product?.images
                                ? product.images.map((image) => {
                                    return {
                                      label: (
                                        <Thumbnail source={image.originalSrc} />
                                      ),
                                      value: image.originalSrc,
                                    }
                                  })
                                : {
                                    label: <Thumbnail source={product.image} />,
                                    value: product.image,
                                  }
                            }
                            selected={selectedImage}
                            onChange={handleImageChange}
                          />
                        </Modal.Section>
                      </Modal>
                    )}
                  </LegacyStack.Item>
                </LegacyStack>
              </LegacyCard.Section>
            </LegacyCard>
          </FeaturedImageWrapper>
        </FormLayout>
      </LegacyCard>
    </div>
  );
}

export default ProductEdit
